import { Component, Input } from '@angular/core';
import { ExhibitionInterface } from '../../../../shared';

@Component({
  selector: 'hm-exhibitions-sial-block',
  templateUrl: './exhibitions-sial-block.component.html',
  styleUrls: ['exhibitions-sial-block.component.scss']
})
export class ExhibitionsSialBlockComponent {

  @Input() exhibitions: ExhibitionInterface[];
}
