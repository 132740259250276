import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, AfterViewInit {

  isBrowser: boolean = isPlatformBrowser(this.platformId);

  constructor(@Inject(PLATFORM_ID) private platformId) {
  }

  ngOnInit(): void {
    if (this.isBrowser) {
      this.loadUIScript('assets/js/script.js');
    }
  }

  ngAfterViewInit(): void {
    if (this.isBrowser) {
      if (location.hash) {
        setTimeout(() => {
          history.replaceState(null, null, ' ');
        }, 2000);
      }
    }
  }

  loadUIScript(script: string): void {
    const scriptsCollection: HTMLCollectionOf<HTMLScriptElement> = document.getElementsByTagName('script');
    const scripts: string[] = scriptsCollection && scriptsCollection.length > 0 ?
      Array.from(scriptsCollection).map((item: HTMLScriptElement) => item.src) : [];
    if (scripts.findIndex((item: string) => item.includes(script)) === -1) {
      const element: HTMLScriptElement = document.createElement('script');
      element.src = script;
      element.type = 'text/javascript';
      document.getElementsByTagName('head')[0].appendChild(element);
    }
  }
}
