import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { SharedModule } from '../modules';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent, LayoutComponent } from './components';
import { APP_EFFECTS, APP_REDUCERS } from './state-management';

@NgModule({
  imports: [
    AppRoutingModule,
    BrowserModule.withServerTransition({ appId: 'my-app' }),
    EffectsModule.forRoot(APP_EFFECTS),
    SharedModule,
    StoreModule.forRoot(APP_REDUCERS),
    TransferHttpCacheModule
  ],
  declarations: [
    AppComponent,
    LayoutComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
