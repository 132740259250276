import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, Inject, PLATFORM_ID } from '@angular/core';

declare var ymaps: any;

@Component({
  selector: 'hm-contacts-section',
  templateUrl: './contacts-section.component.html',
  styleUrls: ['contacts-section.component.scss']
})
export class ContactsSectionComponent implements AfterViewInit {

  isBrowser: boolean = isPlatformBrowser(this.platformId);

  constructor(@Inject(PLATFORM_ID) private platformId) {
  }

  ngAfterViewInit(): void {
    if (this.isBrowser) {
      ymaps.ready(function () {
        const myMap = new ymaps.Map(
          'map',
          {
            center: [55.758951, 37.554492],
            zoom: 15
          },
          {
            searchControlProvider: 'yandex#search'
          }
        );
        const myPlacemark = new ymaps.Placemark(
        [55.756523, 37.554627],
          {
            hintContent: 'ООО «Глобал Фуд Инжиниринг»'
          },
          {
            // Опции.
            // Необходимо указать данный тип макета.
            iconLayout: 'default#image',
            // Своё изображение иконки метки.
            iconImageHref: 'assets/img/location.png',
            // Размеры метки.
            iconImageSize: [180, 237],
            // Смещение левого верхнего угла иконки относительно
            // её "ножки" (точки привязки).
            iconImageOffset: [-90, -237]
          }
        );
        myMap.geoObjects.add(myPlacemark);
        myMap.behaviors.disable('scrollZoom');
      });
    }
  }
}
