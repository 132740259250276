import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ExhibitionLayoutComponent } from './components';

export const exhibitionRoutes: Routes = [
  {
    path: ':type/:id',
    pathMatch: 'full',
    component: ExhibitionLayoutComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(exhibitionRoutes)],
  exports: [RouterModule]
})
export class ExhibitionRoutingModule { }
