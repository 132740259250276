import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, HostListener, Inject, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { interval } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AbstractComponent } from '../../../shared';

@Component({
  selector: 'hm-cover-section',
  templateUrl: './cover-section.component.html',
  styleUrls: ['cover-section.component.scss'],
})
export class CoverSectionComponent extends AbstractComponent implements AfterViewInit {
  isBrowser: boolean = isPlatformBrowser(this.platformId);

  constructor(@Inject(PLATFORM_ID) private platformId, private router: Router) {
    super();
  }

  ngAfterViewInit(): void {
    if (this.isBrowser) {
      this.positionCoverImgFixed();

      interval(500)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
          this.positionCoverImgFixed();
        });
    }
  }

  get isMobile(): boolean {
    return this.isBrowser ? window.innerWidth <= 576 : false;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.positionCoverImgFixed();
  }

  onLogoClicked(detail: string): void {
    document.dispatchEvent(new CustomEvent<any>('tab-change', { detail }));
  }

  onLogoProject(projectUrl: string): void {
    this.router.navigate(['exhibitions', 'sial', projectUrl]);
  }

  positionCoverImgFixed(): void {
    if (this.isBrowser) {
      const elements: HTMLElement[] = Array.from(
        document.getElementsByClassName('cover-img-fixed') as HTMLCollectionOf<HTMLElement>
      );
      const masthead: HTMLElement = document.getElementById('masthead');
      for (const element of elements) {
        let height: number;
        if (element.className.indexOf('apple') !== -1) {
          height = this.isMobile ? 180 : 220;
        } else {
          height = this.isMobile ? 80 : 130;
        }
        const px: string = `${masthead.getBoundingClientRect().height - height}px`;
        if (element.style.top !== px) {
          element.style.top = px;
        }
        if (element.style.visibility !== 'visible') {
          element.style.visibility = 'visible';
        }
      }
    }
  }
}
