import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { NEWS } from '../constants';
import { NewsItemInterface } from '../interfaces';

@Injectable()
export class NewsService {
  news$: Observable<NewsItemInterface[]>;

  private newsSubject$: BehaviorSubject<NewsItemInterface[]> = new BehaviorSubject(null);

  constructor(private httpClient: HttpClient) {
    this.news$ = this.newsSubject$.pipe(filter(data => !!data));

    this.httpClient.get(environment.newsUpdaterUrl + '/blogdata').subscribe(
      (news: NewsItemInterface[]) => {
        this.newsSubject$.next(news);
      },
      () => {
        this.newsSubject$.next(NEWS);
      }
    );
  }

  readNews() {
    return this.httpClient.get(environment.newsUpdaterUrl + '/blogdata');
  }

  writeNews(data) {
    return this.httpClient.post(environment.newsUpdaterUrl + '/updateblog', data);
  }

  postImg(fileName: string, imgToUpload: string) {
    return this.httpClient.put(environment.newsUpdaterUrl + '/updateimg', {
      name: fileName,
      content: imgToUpload,
    });
  }
}
