import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, PLATFORM_ID } from '@angular/core';
import { EXHIBITIONS_SIMA, ExhibitionSimaInterface } from '../../../../shared';

declare var Swiper: any;

@Component({
  selector: 'hm-exhibitions-sima-swiper',
  templateUrl: './exhibitions-sima-swiper.component.html'
})
export class ExhibitionsSimaSwiperComponent {

  exhibitionsSima: ExhibitionSimaInterface[] = EXHIBITIONS_SIMA;
  isBrowser: boolean = isPlatformBrowser(this.platformId);
  swiper: any;

  constructor(@Inject(PLATFORM_ID) private platformId) {
  }

  init(): void {
    setTimeout(() => {
      if (this.isBrowser) {
        this.swiper = new Swiper('#sima-swiper', {
          // Optional parameters
          direction: 'horizontal',
          loop: false,
          // Navigation arrows
          navigation: {
            nextEl: '#sima-swiper-button-next',
            prevEl: '#sima-swiper-button-prev',
          }
        });
      }
    }, 200);
  }
}
