import { NewsItemInterface } from '../interfaces';

export const NEWS: NewsItemInterface[] = [
  {
    idString: 'sial-middle-east-2019-россия-бьёт-рекорды',
    title: 'SIAL Middle East 2019: Россия бьёт рекорды',
    date: '12/10/2019',
    image: '/assets/img/news/news-1.jpeg',
    description: 'На открывшемся 9 декабря международном салоне продовольствия и напитков SIAL Middle East 2019 в Абу Даби свыше двух десятков экспортеров из семи регионов РФ представили свою продукцию. <br>',
    content:
      'На открывшемся 9 декабря международном салоне продовольствия и напитков SIAL Middle East 2019 в Абу Даби свыше двух десятков экспортеров из семи регионов РФ представили свою продукцию. Выставка стартовала в Национальном выставочном центре Абу-Даби и проводится под патронажем члена правящей королевской семьи эмирата Абу-Даби, шейха Мансура ибн Зайда Аль Нахайяна.<br>' +
      'В открытии павильона России приняли участие официальные лица: торгпред РФ в ОАЭ г-н Ара Меликян и Поверенный в делах России в ОАЭ г-н Юрий Видакас. Павильон РФ был также удостоен визита официальной делегации ОАЭ: производители мёда из Башкортостана смогли лично презентовать свою продукцию шейху Мансуру ибн Зайда Аль Нахайяна.<br>' +
      'В числе регионов-участников РФ – Тула, Республика Башкортостан, Кабардино-Балкария, Омская, Костромская, Тамбовская и Вологодская области, участие состоялось при поддержке региональных центров по поддержке экспорта. Российские производители привезли в столицу ОАЭ разные сорта меда, растительное масло, охлажденное мясо птицы (перепела), фрукты и семечки, минеральную воду, рыбу, шоколад и другие кондитерские изделия. Всего в выставке принимают участие свыше 1000 участников из 30 стран мира и более 25.000 профессиональных посетителей из 86 стран – потенциальных покупателей продукции: импортеры, ритейлеры, продавцы, сотрудники отелей и ресторанов. Посетителей ждет обширный ассортимент продуктов питания, безалкогольных напитков, вин и спиртных напитков, продукция и услуги индустрии гостеприимства, последние технологии и специальное оборудование пищевого и упаковочного производства.<br>' +
      'Это не первый масштабный проект России на выставке: ранее, в 2017г., Россия также представила масштабную экпозицию при поддержке АО Российский Экспортный Ценрт. Международная выставка продуктов питания и напитков SIAL Middle East 2019 является ежегодной и продлится до 11 декабря. По мнению экспертов, она является отличной возможностью для производителей увеличить продажи и найти новых клиентов. Оператором экспозиции традиционно стала компания «Глобал Фуд Инжиниринг» — эксклюзивное представительство SIAL в России и странах СНГ.<br>'
  },
  {
    idString: 'sial-india-2019-курс-на-развитие',
    title: 'SIAL India 2019: курс на развитие',
    date: '10/10/2019',
    image: '/assets/img/news/news-2.jpg',
    description: 'Сентябрь в Индии – жаркое время не только для туристов, но и для экспортеров со всего мира.',
    content:
      'Сентябрь в Индии – жаркое время не только для туристов, но и для экспортеров со всего мира. С 19 по 21 сентября 2019 года в Дели прошла крупнейшая в регионе международная выставка производителей продуктов питания и напитков SIAL India.' +
      'Деловой салон собрал более 300 компаний со всего мира. Россия была представлена производителями кондитерской продукции, снеков, макаронных изделий, алкоголя и заняла почётное место в 5-ке самых крупных национальных представительств. Российские продукты вызвали высокий интерес у индийских байеров, а наши участники были традиционно были отмечены, как одни из самых доброжелательных и гостеприимных.' +
      'Стенды российских экспортеров также посетила делегация Торгпредства России в Индии – переговорить об особенностях экспорта в этот регион и поделиться полезными контактами.' +
      'Индия — важнейший торговый партнер и приоритетный регион, на который направлен фокус развития российского несырьевого экспорта. По данным Российского экспортного центра за 2018 год товарооборот между странами вырос на 17,3% и составил $11 млрд. К 2025 году цель сторон – достичь планки в $30 млрд.'
  },
  {
    idString: 'экспорт-в-китай-оценка-потенциала',
    title: 'Экспорт в Китай: оценка потенциала',
    date: '10/08/2019',
    image: '/assets/img/news/news-3.jpg',
    link: 'https://www.kp.ru/putevoditel/spetsproekty/exportcenter/otkrytie-kitaya/?fbclid=IwAR0JS3SP2UEIhkKxKoGYhnHqhuTFCoHmrJU7OCZcHjXrWqk91kSombV6CnU',
    description: 'Комсомольская правда об экспортном рынке Поднебесной.',
    content: ''
  },
  {
    idString: 'sial-china-2019-дим-самы-и-лапша-теряют-позиции',
    title: 'SIAL China 2019: Дим самы и лапша теряют позиции',
    date: '07/05/2019',
    image: '/assets/img/news/news-4.jpg',
    description: 'Небывалая популярность российских продуктов! Гречка стремительно заменяет рис в рационе жителей Поднебесной!',
    content:
      'Небывалая популярность российских продуктов! Гречка стремительно заменяет рис в рационе жителей Поднебесной! Когда-нибудь такими заголовками обязательно запестрят СМИ – российские экспортёры активно наращивают сотрудничество с Китаем.' +
      'В Шанхае, где с 14 по 16 мая 2019 года проходила одна из крупнейших в мире выставок производителей питания SIAL China 2019, экспозиция российских производителей стала одной из самых представительных. Посетители выставки также отметили яркий индивидуальный дизайн объединённого стенда Made in Russia. В коллективном павильоне, на площади 1500 метров, разместились более 100 российских компаний: Объединенные кондитеры Uniconf, Группа «Черкизово» , Мираторг , Акконд» — кондитерская фабрика, Невский кондитер, Группа агропредприятий «Ресурс», Агама, Фанагория и многие другие крупные производители, уже имеющие деловые связи с Китаем, так и представители среднего и малого бизнеса, только начинающие знакомство с этим рынком.' +
      'На SIAL China 2019 года российские экспортеры подписали контракты на сумму 855 млн рублей. Широкое представительство российского бизнеса стало возможным, в частности, благодаря поддержке Российский экспортный центр, Московский Экспортный Центр и региональным центрам поддержки экспорта.' +
      'Мы благодарим всех участников выставки SIAL CHINA 2019, желаем реализации экспортных планов и будем рады видеть вновь!'
  },
  {
    idString: 'sial-выступил-стратегическим-партнером-конференции-по-экспорту',
    title: 'SIAL выступил стратегическим партнером конференции по экспорту',
    date: '12/25/2018',
    image: '/assets/img/news/news-5.jpg',
    description: 'Конференция для российских экспортёров на тему: «Экспорт в страны Юго-Восточной Азии. Работа над ошибками и успешные ВЭД кейсы» прошла 14 декабря в Москва-Сити.',
    content:
      'Конференция для российских экспортёров на тему: «Экспорт в страны Юго-Восточной Азии. Работа над ошибками и успешные ВЭД кейсы» прошла 14 декабря в Москва-Сити. В ней приняли участие 104 делегата и порядка 20 спикеров – представители крупных российских компаний-экспортеров. Многие из участников конференции являются партнёрами SIAL в лице ООО «Глобал Фуд Инжиниринг»: Балтика, Макфа, Фанагория, Агросоюз, Нарзан, Русбиоальянс и другие.' +
      'В течение дня обсуждались практические вопросы экспорта в Китай, Японию, Южную Корею и другие страны Юго-Восточной Азии, навыки переговорных стратегий, разбирались основные ошибки и успешные кейсы российских компаний.' +
      'Традиционно было отмечено, что эффективным способом знакомства с рынком данного региона и налаживания контактов являются выставки. В частности, SIAL China – крупнейшее событие для всех мировых производителей продуктов питания и напитков.' +
      'В 2019 году SIAL China пройдет в Шанхае с 14 по 16 мая и Представительство SIAL в России приглашает всех российских экспортёров реализовать полученные в рамках конференций и воркшопов знания на практике.'
  },
  {
    idString: 'gulf-производителям-из-рф-зелёный-свет',
    title: 'Gulf: производителям из РФ — зелёный свет!',
    date: '12/14/2018',
    image: '/assets/img/news/news-6.jpg',
    description: 'Гостеприимно, солнечно, вкусно, а главное — эффективно! Так отзываются наши российские экспоненты о выставке SIAL Middle East в Абу Даби.',
    content:
      'Гостеприимно, солнечно, вкусно, а главное — эффективно! Так отзываются наши российские экспоненты о выставке SIAL Middle East в Абу Даби.' +
      'Это три дня, которые ежегодно с нетерпением ждут ведущие производители продуктов питания и напитков во всём мире. В этом году одна из крупнейших в ближневосточном регионе выставка прошла с 10 по 12 декабря — более 1000 экспонентов, участники из 40 стран, профессиональные посетители, отлаженная система матч-мейкинга.' +
      'Высокий уровень организации поддерживался и высоким уровнем гостей — SIAL Middle East традиционно проходит под патронажем Его Высочества шейха Мансура бен Заида аль-Нахайяна, члена правящей королевской семьи эмирата Абу-Даби.' +
      'Российские компании, которые стремятся укрепиться на рынке ближневосточного региона, высоко ценят эту выставку. Подтверждение этому — участие в SIAL Middle East лидеров рынка, таких как Объединённые кондитеры, Дальпромрыба, Невский Кондитер, Акконд и другие.' +
      'Через год SIAL Middle East вновь ждёт экспонентов со всего мира. Присоединяйтесь!'
  }
];
