import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { LinkInterface } from '../../interfaces';

@Component({
  selector: 'sh-header',
  templateUrl: './header.component.html',
  styleUrls: ['header.component.scss']
})
export class HeaderComponent {

  isBrowser: boolean = isPlatformBrowser(this.platformId);

  links: LinkInterface[] = [
    { href: '#about', title: 'О нас' },
    { href: '#sial', title: 'SIAL', bold: true, onClick: () => { document.dispatchEvent(new CustomEvent<any>('tab-change', { detail: 'exhibitions-sial' })); } },
    { href: '#sima', title: 'SIMA', bold: true, onClick: () => { document.dispatchEvent(new CustomEvent<any>('tab-change', { detail: 'exhibitions-sima' })); } },
    { href: '#projects', title: 'Проекты', onClick: () => { document.dispatchEvent(new CustomEvent<any>('tab-change', { detail: 'exhibitions-projects' })); } },
    { href: '#news', title: 'Новости' },
    { href: '#partners', title: 'Партнеры' },
    { href: '#contacts', title: 'Контакты' }
  ];

  constructor(@Inject(PLATFORM_ID) private platformId) {
  }

  onLinkClicked(link: LinkInterface): void {
    if (link.onClick) {
      link.onClick();
    }
  }
}
