import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, HostListener, Inject, PLATFORM_ID } from '@angular/core';
import { interval } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { AbstractComponent, NewsItemInterface, NewsService } from '../../../../shared';

declare var Swiper: any;

@Component({
  selector: 'hm-news-section',
  templateUrl: './news-section.component.html',
  styleUrls: ['news-section.component.scss']
})
export class NewsSectionComponent extends AbstractComponent implements AfterViewInit {

  isBrowser: boolean = isPlatformBrowser(this.platformId);
  newsBlocks: NewsItemInterface[][];
  swiper: any;

  private mobile: boolean;
  private swiperInitialized: boolean = false;

  constructor(@Inject(PLATFORM_ID) private platformId, private chRef: ChangeDetectorRef, private newsService: NewsService) {
    super();
    if (this.isBrowser) {
      interval(1000).pipe(takeUntil(this.destroyed$)).subscribe(() => {
        this.checkNewsBlock();
      });
    }
  }

  get isMobile(): boolean {
    return this.isBrowser ? window.innerWidth <= 768 : false;
  }

  ngAfterViewInit(): void {
    this.checkNewsBlock();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkNewsBlock();
  }

  private checkNewsBlock(): void {
    if (this.isBrowser) {
      if (this.isMobile !== this.mobile) {
        this.mobile = this.isMobile;
        this.initNewsBlocks();
      }
    }
  }

  private initNewsBlocks(): void {
    this.newsService.news$.pipe(take(1)).subscribe((news: NewsItemInterface[]) => {
      news.sort((newsA, newsB) =>
         new Date(newsA.date).getTime() > new Date(newsB.date).getTime() ? -1 : 1
      );
      this.newsBlocks = [];
      if (this.mobile) {
        for (const newsItem of news) {
          this.newsBlocks.push([newsItem]);
        }
      } else {
        const blockSize: number = 3;
        for (let i = 0; i < news.length; i += blockSize) {
          const block: NewsItemInterface[] = news.slice(i, i + blockSize);
          this.newsBlocks.push(block);
        }
      }
      this.chRef.detectChanges();
      if (!this.swiperInitialized) {
        this.initSwiper();
      }
    });
  }

  private initSwiper(): void {
    if (this.isBrowser) {
      this.swiper = new Swiper('.swiper-container', {
        // Optional parameters
        direction: 'horizontal',
        loop: false,
        // Navigation arrows
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }
      });
    }
  }
}
