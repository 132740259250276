import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'sh-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['footer.component.scss'],
})
export class FooterComponent implements OnInit {
  isBrowser: boolean = isPlatformBrowser(this.platformId);
  showWhatsApp: boolean = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private readonly router: Router
  ) {}

  ngOnInit() {
    const exhibitions = 'exhibitions';
    this.showWhatsApp = this.router.url.search(exhibitions) !== -1;
  }
}
