import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { take } from 'rxjs/operators';
import { NewsItemInterface, NewsService } from '../../../shared';

@Component({
  selector: 'nw-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['layout.component.scss']
})
export class NewsLayoutComponent implements OnInit {

  data: NewsItemInterface;
  date: string;

  constructor(private activatedRoute: ActivatedRoute, private newsService: NewsService) {
  }

  ngOnInit(): void {
    this.newsService.news$.pipe(take(1)).subscribe((news: NewsItemInterface[]) => {
      this.activatedRoute.params.pipe(take(1)).subscribe((params: Params) => {
        this.data = news.find((item) => item.idString === params.id);
        const dateParts: string[] = this.data.date.split('/');
        const date: Date = new Date(Number(dateParts[2]), Number(dateParts[0]) - 1, Number(dateParts[1]));
        this.date = date.toLocaleString('ru', {  month: 'long', day: '2-digit', year: 'numeric' });
        this.date = this.date.slice(0, this.date.length - 3);
      });
    });
  }
}
