import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { NewsService } from '../services';

@Injectable()
export class NewsResolver implements Resolve<boolean> {

  constructor(private newsService: NewsService) {
  }

  resolve(): Observable<boolean> {
    return this.newsService.news$.pipe(take(1), map(() => true));
  }
}
