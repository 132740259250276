import { Component, Input } from '@angular/core';
import { ExhibitionProjectInterface } from '../../../../shared';

@Component({
  selector: 'hm-exhibition-project',
  templateUrl: './exhibition-project.component.html',
  styleUrls: ['exhibition-project.component.scss']
})
export class ExhibitionProjectComponent {

  @Input() data: ExhibitionProjectInterface;
}
