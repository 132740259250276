import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared';
import { ExhibitionLayoutComponent } from './components';
import { ExhibitionRoutingModule } from './exhibition-routing.module';

@NgModule({
  imports: [
    CommonModule,
    ExhibitionRoutingModule,
    SharedModule
  ],
  declarations: [
    ExhibitionLayoutComponent
  ]
})
export class ExhibitionModule { }
