import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './components';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        loadChildren: () => import('../modules/home').then(m => m.HomeModule)
      },
      {
        path: 'exhibitions',
        loadChildren: () => import('../modules/exhibition').then(m => m.ExhibitionModule)
      },
      {
        path: 'news',
        loadChildren: () => import('../modules/news').then(m => m.NewsModule)
      },
      {
        path: 'newsupdater',
        loadChildren: () => import('../modules/newsupdater').then(m => m.NewsUpdaterModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { initialNavigation: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
