import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared';
import {
  AboutSectionComponent,
  ContactsSectionComponent,
  CoverSectionComponent,
  ExhibitionProjectComponent,
  ExhibitionSialComponent,
  ExhibitionSimaComponent,
  ExhibitionsProjectsSwiperComponent,
  ExhibitionsSectionComponent,
  ExhibitionsSialBlockComponent,
  ExhibitionsSialSwiperComponent,
  ExhibitionsSimaSwiperComponent,
  HomeLayoutComponent,
  NewsBlockComponent,
  NewsItemComponent,
  NewsSectionComponent,
  PartnersSectionComponent,
  ServicesSectionComponent
} from './components';
import { HomeRoutingModule } from './home-routing.module';

@NgModule({
  imports: [
    CommonModule,
    HomeRoutingModule,
    SharedModule
  ],
  declarations: [
    AboutSectionComponent,
    ContactsSectionComponent,
    CoverSectionComponent,
    ExhibitionsSialSwiperComponent,
    ExhibitionsProjectsSwiperComponent,
    ExhibitionsSimaSwiperComponent,
    ExhibitionProjectComponent,
    ExhibitionsSialBlockComponent,
    ExhibitionSimaComponent,
    ExhibitionSialComponent,
    ExhibitionsSectionComponent,
    HomeLayoutComponent,
    NewsBlockComponent,
    NewsItemComponent,
    NewsSectionComponent,
    PartnersSectionComponent,
    ServicesSectionComponent
  ]
})
export class HomeModule { }
