import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, Inject, PLATFORM_ID } from '@angular/core';
import { AbstractComponent } from '../../../../shared';

declare var $: any;

@Component({
  selector: 'hm-exhibitions-section',
  templateUrl: './exhibitions-section.component.html',
  styleUrls: ['exhibitions-section.component.scss']
})
export class ExhibitionsSectionComponent extends AbstractComponent implements AfterViewInit {

  isBrowser: boolean = isPlatformBrowser(this.platformId);

  constructor(@Inject(PLATFORM_ID) private platformId) {
    super();
  }

  ngAfterViewInit(): void {
    if (this.isBrowser) {
      document.addEventListener('tab-change', (e: any) => {
        this.openTab(e.detail);
      }, false);

      if (location.href.endsWith('#sial')) {
        this.openTab('exhibitions-sial');
      } else if (location.href.endsWith('#sima')) {
        this.openTab('exhibitions-sima');
      } else if (location.href.endsWith('#projects')) {
        this.openTab('exhibitions-projects');
      }
    }
  }

  private openTab(tab: string): void {
    const elem: HTMLLinkElement = document.getElementById(tab + '-btn') as HTMLLinkElement;
    elem.click();
  }
}
