import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  Input,
  PLATFORM_ID,
} from '@angular/core';
import { interval } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AbstractComponent, ExhibitionInterface, EXHIBITIONS_SIAL } from '../../../../shared';

declare var Swiper: any;

@Component({
  selector: 'hm-exhibitions-swiper',
  templateUrl: './exhibitions-sial-swiper.component.html',
  styleUrls: ['exhibitions-sial-swiper.component.scss'],
})
export class ExhibitionsSialSwiperComponent extends AbstractComponent implements AfterViewInit {
  @Input() type: 'sial' | 'projects' = 'sial';
  @Input() swiperTag: string;

  exhibitionsSialBlocks: ExhibitionInterface[][];
  isBrowser: boolean = isPlatformBrowser(this.platformId);
  swiper: any;

  private mobile: boolean;
  private swiperInitialized: boolean = false;
  private tablet: boolean;

  constructor(@Inject(PLATFORM_ID) private platformId, private chRef: ChangeDetectorRef) {
    super();

    if (this.isBrowser) {
      interval(1000)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
          this.checkExhibitionsSialBlocks();
        });
    }
  }

  get isMobile(): boolean {
    return this.isBrowser ? window.innerWidth <= 768 : false;
  }

  get isTablet(): boolean {
    return this.isBrowser ? window.innerWidth <= 1045 && !this.isMobile : false;
  }

  ngAfterViewInit(): void {
    this.checkExhibitionsSialBlocks();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkExhibitionsSialBlocks();
  }

  init(): void {
    setTimeout(() => {
      this.initSwiper();
    }, 200);
  }

  private checkExhibitionsSialBlocks(): void {
    if (this.isBrowser) {
      if (this.isMobile !== this.mobile || this.isTablet !== this.tablet) {
        this.mobile = this.isMobile;
        this.tablet = this.isTablet;
        this.initExhibitionsSialBlocks();
      }
    }
  }

  private initExhibitionsSialBlocks(): void {
    this.exhibitionsSialBlocks = [];
    const blockSize: number = this.mobile ? 1 : this.tablet ? 2 : 3;
    const EXHIBITIONS = EXHIBITIONS_SIAL.sort((first, second) => first.pos - second.pos).filter(
      exib => (this.type === 'sial' && exib.type !== 'projects') || exib.type === this.type
    );
    for (let i = 0; i < EXHIBITIONS.length; i += blockSize) {
      const block: ExhibitionInterface[] = EXHIBITIONS.slice(i, i + blockSize);
      this.exhibitionsSialBlocks.push(block);
    }

    this.chRef.detectChanges();
    if (!this.swiperInitialized) {
      this.initSwiper();
    }
  }

  private initSwiper(): void {
    if (this.isBrowser) {
      this.swiper = new Swiper(`#${this.swiperTag}-swiper`, {
        // Optional parameters
        direction: 'horizontal',
        loop: false,
        // Navigation arrows
        navigation: {
          nextEl: `#${this.swiperTag}-swiper-button-next`,
          prevEl: `#${this.swiperTag}-swiper-button-prev`,
        },
      });
    }
  }
}
