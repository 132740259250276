// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular.json`.

export const environment = {
  host: 'http://localhost:4000',
  newsUrl: 'http://localhost:4200/data/news.json',
  newsUpdaterUrl: 'https://api.produktexpo.com', //http://localhost:3000 https://api.produktexpo.com
  production: false
};
