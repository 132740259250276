import {
  ExhibitionInterface,
  ExhibitionProjectInterface,
  ExhibitionSimaInterface,
} from '../interfaces';

export const EXHIBITIONS_SIAL: ExhibitionInterface[] = [
  {
    city: 'Париж',
    cityImage: 'assets/img/exhibitions/sial/paris-city.png',
    date: '19-23 октября 2024',
    pos: 20,
    description:
      'Выставка SIAL Paris впервые прошла в Париже в 1964 году и с тех пор является самым авторитетным ' +
      'событием в мире продовольственных салонов. Компании – производители продуктов питания и напитков со всего мира ' +
      'представляют на SIAL Paris свои продукты, инновации и технологии. Салон по праву считается мировым деловым центром, ' +
      'который предлагает уникальные возможности для экспортного бизнеса.',
    descriptionShort: 'Продовольственная выставка номер один в мире',
    idString: 'paris',
    image: 'assets/img/exhibitions/sial/paris.png',
    location: 'Paris Nord Villepinte',
    name: 'SIAL Paris',
    plateImage: 'assets/img/exhibitions/sial/paris-plate.jpg',
    star: {
      titleTop: '86%',
      subtitleTop: 'участников удовлетворены',
      titleBottom: '92%',
      subtitleBottom: 'вновь примут участие',
    },
    firstCol: {
      name: 'Показатели рынка',
      items: [
        'Население Евросоюза - <strong>513 млн.</strong> человек',
        'Ведущие технологии в сфере продовольствия',
        'Ежегодный рост ВВП',
      ],
    },
    secondCol: {
      name: 'Ключевые показатели 2020',
      items: [
        'Посетители из <strong>200</strong> стран',
        'Более <strong>310 000</strong> профессиональных посетителей',
        '<strong>7200</strong> участников из <strong>119</strong> стран',
        '<strong>87%</strong> интернациональных участников',
        '<strong>135</strong> официальных делегаций',
      ],
    },
    videos: [
      {
        name: 'Смотреть видео (SIAL Paris 2018):',
        link: 'https://www.youtube.com/embed/MLLQr1RJ7Ak',
      },
    ],
  },
  {
    city: 'Шанхай',
    cityImage: 'assets/img/exhibitions/sial/shanghai-city.png',
    date: '28-30 мая 2024',
    pos: 18,
    description:
      'SIAL SHANGHAI - ведущая продуктовая выставка Азии и одна из крупнейших в мире. Выставка незаменима для ' +
      'зарубежных компаний, которые выходят на рынок данного региона, дает полное представление об основных трендах и ' +
      'тенденциях региональной пищевой промышленности. Салон собирает всех основных игроков рынка розничной торговли, ' +
      'общественного питания, гостиничного и ресторанного бизнеса. В 2021 году экспозиция российских производителей продуктов ' +
      'питания и напитков будет расположена на площади более 1500 м2.',
    descriptionShort: 'Самая большая азиатская инновационная выставка',
    idString: 'shanghai',
    image: 'assets/img/exhibitions/sial/shanghai.png',
    location: 'National Exhibition and Convention Center',
    name: 'SIAL SHANGHAI',
    plateImage: 'assets/img/exhibitions/sial/shanghai-plate.jpg',
    star: {
      titleTop: '95%',
      subtitleTop: 'участников удовлетворены',
      titleBottom: '99%',
      subtitleBottom: 'гостей удовлетворены',
    },
    firstCol: {
      name: 'Показатели рынка',
      items: [
        'Население - <strong>1.4 млрд.</strong> человек',
        '<strong>56%</strong> населения проживает в городах',
        'Продовольственный сектор - самый быстрорастущий в мире',
        'Устойчивый рост потребления (<strong>4.4%</strong> за последний год)',
      ],
    },
    secondCol: {
      name: 'Ключевые показатели 2022:',
      items: [
        '<strong>4500</strong> участников из <strong>70 стран</strong>',
        '<strong>150 000</strong> профессиональных посетителей',
        '<strong>38</strong> национальных павильона. Объединённая экспозиция российских компаний вошла в 2022 году в число крупнейших.',
      ],
    },
    files: [
      {
        name: 'Скачать буклет (SIAL China 2020)',
        link: 'https://media.produktexpo.com/SIAL_China_2020_%D0%904.pdf',
      },
    ],
    videos: [
      {
        name: 'Смотреть видео (SIAL China 2019):',
        link: 'https://www.youtube.com/embed/ujwud3QUw-s',
      },
    ],
  },
  {
    city: 'Куала-Лумпур',
    cityImage: 'assets/img/exhibitions/sial/abu-dhabi-city.png', // todo заменить на нормальный лого
    date: '4-6 июля 2023',
    pos: 9,
    description:
      'Разнообразная кулинарная культура Малайзии проистекает из ее колониального прошлого - смесь местных и иностранных влияний Малайского архипелага, Индии, Китая, Ближнего Востока и нескольких европейских стран. Этот плавильный котел разнообразных культур и традиций получил широкое признание, а индустрия продуктов питания и напитков в Малайзии занимает свое особое положение на рынке, которого нет больше нигде в мире. ' +
      'Расположенная в центре Юго-Восточной Азии, Малайзия насчитывает 32.7 млн. человек (данные на 2022г.)',
    descriptionShort: '3-ая экономика Юго-Восточной Азии',
    idString: 'kuala-lumpur',
    image: 'assets/img/exhibitions/sial/abu-dhabi.png',
    location: 'Mitec Kuala Lumpur',
    name: 'Food & Drinks Malaysia',
    multiline: true,
    plateImage: 'assets/img/exhibitions/sial/malaysian-food.jpg',
    star: {
      titleTop: '73%',
      subtitleTop: 'мирового рынка',
      titleBottom: '10%',
      subtitleBottom: 'ежегодный рост',
    },
    firstCol: {
      name: 'Профиль выставки',
      items: [
        'Продукты питания и напитки',
        'Рыба и морепродукты',
        'Продукты Халяль',
        'Технологии производства продуктов питания и напитков',
        'Специи и приправы',
        'Альтернативные источники белка',
      ],
    },
    // secondCol: {
    //   name: 'Ключевые показатели 2020',
    //   items: [
    //     '<strong>700</strong> участников из <strong>50</strong> стран',
    //     '<strong>20 500</strong> профессиональных посетителей',
    //     'Более <strong>20</strong> российских компаний-экспортёров из <strong>7</strong> регионов РФ',
    //   ],
    // },
  },
  {
    city: 'Нью-Дели',
    cityImage: 'assets/img/exhibitions/sial/new-delhi-city.png',
    date: '9-11 декабря 2023',
    pos: 60,
    description:
      'SIAL INDIA – новое перспективное направление группы SIAL и лучшая возможность для выхода на рынок страны с одной из самых быстрорастущих экономик в мире.',
    descriptionShort: 'Высокий потенциал развития в новом регионе',
    idString: 'new-delhi',
    image: 'assets/img/exhibitions/sial/new-delhi.png',
    location: 'Pragati Maidan Exhibition Centre',
    plateImage: 'assets/img/exhibitions/sial/new-delhi-plate.jpg',
    name: 'SIAL India',
    firstCol: {
      name: 'Показатели рынка',
      items: [
        'Население - <strong>1.3 млрд.</strong> человек',
        'Наиболее быстро растущая экономика в мире: <strong>+7.6%</strong> в год',
        'Средний класс ежегодно увеличивается на <strong>20 млн.</strong> человек ',
        'К <strong>2025 году</strong> индийская экономика войдёт в тройку крупнейших в мире',
      ],
    },
    secondCol: {
      name: 'Ключевые показатели 2022',
      items: [
        'Более <strong>500</strong> участников из <strong>40 стран</strong>',
        'Доля международного участия - <strong>30%</strong>',
        '<strong>80%</strong> удовлетворены участием в выставке',
      ],
    },
    videos: [
      {
        name: 'Смотреть видео (SIAL India 2019):',
        link: 'https://www.youtube.com/embed/_uex1Yzof4Q',
      },
    ],
  },
  {
    city: 'Монреаль',
    cityImage: 'assets/img/exhibitions/sial/monreale-city.png',
    date: '21-23 сентября 2021',
    pos: 80,
    description:
      'SIAL Canada - одна из крупнейших международных выставок производителей продуктов питания и ' +
      'оборудования для пищевой промышленности. Организатор - ADA (Association des détaillants en alimentation - ' +
      'агропродовольственная группа из Квебека). Выставка поддерживается федеральным и региональным правительством и ' +
      'объединяет под одной крышей индустрии розничной торговли, общепита и оборудования. Салон знаменит многочисленными ' +
      'мероприятиями - La Cuisine, The Lab of Senses, Olive d’Or, SIAL Innovation и другими, представляющими все ' +
      'инновационные и креативные подходы в сфере мировой пищевой индустрии.',
    descriptionShort: 'Новые возможности для Вашего бизнеса',
    idString: 'monreale',
    image: 'assets/img/exhibitions/sial/monreale.png',
    location: 'Palais Des Congres',
    name: 'SIAL Canada',
    plateImage: 'assets/img/exhibitions/sial/monreale-plate.jpg',
    star: {
      titleTop: '97%',
      subtitleTop: 'участников удовлетворены',
      titleBottom: '94%',
      subtitleBottom: 'гостей удовлетворены',
    },
    firstCol: {
      name: 'Показатели рынка',
      items: [
        'Население - <strong>37 млн.</strong> человек',
        '<strong>Вторая</strong> страна в мире по количеству эмигрантов',
        '<strong>Номер 2</strong> в мире по торговле и производству продуктов питания и напитков',
      ],
    },
    secondCol: {
      name: 'Ключевые показатели 2021',
      items: [
        '<strong>25 000</strong> профессиональных посетителей',
        '<strong>1130</strong> участников из <strong>45</strong> стран',
        '<strong>28</strong> международных павильонов',
        'Более <strong>15 000</strong> посетителей из <strong>65</strong> стран',
      ],
    },
    videos: [
      {
        name: 'Смотреть видео (SIAL Canada 2019):',
        link: 'https://www.youtube.com/embed/rw_O4kETsdA',
      },
    ],
  },
  {
    city: 'DJAZAGRO',
    // cityImage: 'assets/img/exhibitions/sial/djazagro-city.png',
    // citySmallImage: 'assets/img/exhibitions/sial/djazagro-city-small.png',
    dark: true,
    date: '22-25 апрель 2024',
    pos: 40,
    description: `DJAZAGRO – международная выставка сельскохозяйственной продукции. Основана в 2003 году, проводится ежегодно.
      Выставка является наиболее авторитетным мероприятием в Северной Африке для представителей агробизнеса.
      В рамках DJAZAGRO проводятся деловые встречи и семинары по экспорту и инвестированию, конкурс инновационных продуктов  DJAZ'INNOV,
      а также работает «Пекарня будущего», где можно наблюдать за процессом выпечки хлебобулочных изделий в соответствии с самыми последними технологиями.
      <br>
      <br>
      Разделы выставки:
        <ul>
        <li>Кондитерские изделия, выпечка</li>
        <li>Пищевые добавки и ароматизаторы</li>
        <li>Продукты и напитки</li>
        <li>Гостиничный и ресторанный сервис</li>
        <li>Ингредиенты и ароматизаторы</li>
        <li>Переработка, расфасовка и упаковка</li>
        </ul>
        <br>
        <a href='https://en.djazagro.com/' target="_blank">Официальный Сайт</a>`,
    descriptionShort: '',
    idString: 'djazagro',
    image: 'assets/img/exhibitions/sial/djazagro.png',
    location: 'Алжир, выставочный центр SAFEX',
    name: 'DJAZAGRO',
    plateImage: 'assets/img/exhibitions/sial/djazagro-plate.jpg',
    star: {
      titleTop: '88%',
      subtitleTop: 'участников удовлетворены',
      titleBottom: '93%',
      subtitleBottom: 'гостей удовлетворены',
    },
    firstCol: {
      name: 'Ключевые показатели 2023:',
      items: [
        'Более <strong>23 000</strong> профессиональных посетителя',
        'Более <strong>700</strong> участников со всего мира',
        '<strong>94%</strong> посетителей планируют посетить выставку в следующем году',
      ],
    },
    secondCol: {
      name: 'Показатели экономики страны:',
      items: [
        '<strong>Третья по величине</strong> экономика регионов Ближний Восток и Северная Африка',
        'Население - <strong>42.4</strong> млн человек',
        '<strong>70%</strong> проживают в городах ',
        '<strong>75%</strong> потребностей сельскохозяйственного сектора удовлетворяются за счёт экспорта',
        'Основные партнёры: <strong>Евросоюз, Китай</strong>',
      ],
    },
    videos: [
      {
        name: 'Смотреть видео (DJAZAGRO 2019):',
        link: 'https://www.youtube.com/embed/UtEhngVGfis',
      },
    ],
  },
  {
    city: 'Джакарта',
    cityImage: 'assets/img/exhibitions/sial/jakarta-city.png',
    citySmallImage: 'assets/img/exhibitions/sial/jakarta-city-small.png',
    dark: true,
    date: '8-11 ноября 2023',
    pos: 15,
    description:
      'Статус Индонезии как экономической державы АСЕАН с быстрорастущим средним классом делает эту страну ' +
      'идеальной платформой для SIAL Interfood. Выставка ежегодно представляет все актуальные тенденции в сфере ' +
      'производства продуктов питания, которые в скором будущем станут определяющими для динамично развивающего региона.',
    descriptionShort: 'Прямой выход на динамичный рынок',
    idString: 'jakarta',
    image: 'assets/img/exhibitions/sial/jakarta.png',
    location: 'Jiexpo, Kemayoran',
    name: 'SIAL Interfood',
    plateImage: 'assets/img/exhibitions/sial/jakarta-plate.jpg',
    star: {
      titleTop: '84%',
      subtitleTop: 'участников удовлетворены',
      titleBottom: '95%',
      subtitleBottom: 'гостей удовлетворены',
    },
    firstCol: {
      name: 'Показатели рынка',
      items: [
        'Население - <strong>265 млн.</strong> человек',
        '<strong>4-я</strong> по численности населения страна мира',
        'Устойчивый рост среднего класса (<strong>135 млн.</strong> к <strong>2030 году</strong>)',
      ],
    },
    secondCol: {
      name: 'Ключевые показатели 2023',
      items: [
        'Более <strong>1500</strong> участников из <strong>30 стран</strong>',
        'Более <strong>95 000</strong> профессиональных посетителей',
        'Посетители из <strong>72 стран</strong> мира',
      ],
    },
    videos: [
      {
        name: 'Смотреть видео (SIAL Interfood 2017):',
        link: 'https://www.youtube.com/embed/DODt2aGy7z8',
      },
    ],
  },
  {
    city: 'Шэньчжэнь',
    cityImage: 'assets/img/exhibitions/sial/shanghai-city.png',
    date: '28 - 30 августа 2023',
    pos: 5,
    description: `SIAL SHENZHEN – новый продовольственный салон, амбициозное продолжение
      SIAL China в Шанхае. Салон призван укрепить авторитет SIAL China. Кроме
      того, его главная задача - дать возможность экспортёрам расширить своё
      присутствие на рынке Китая, охватив новый регион на юге страны.
      В рамках SIAL SHENZHEN пройдут различные мероприятия, где представители
      отрасли могут встретиться, обменяться информацией и скоординировать свои
      действия. SIAL SHENZHEN также представит целый ряд новых онлайн-
      материалов, услуг и решений для развития торговли. Онлайн-площадка и
      интегрированное решение по установлению связей позволят экспонентам и
      посетителям согласовывать, а затем проводить «встречи» на протяжении
      всего года.
      Группа компаний Comexposium – один из ведущих в мире организаторов
      выставочных мероприятий. Comexposium ежегодно проводит более 135
      выставок B2B и B2B2C в 30 странах в таких отраслях как сельское хозяйство,
      продовольствие, здоровье, недвижимость, мода, туризм.`,
    descriptionShort: 'Новые возможности для Вашего бизнеса',
    idString: 'shenzhen',
    image: 'assets/img/exhibitions/sial/shanghai.png',
    location: 'Shenzhen world exhibition & convention center',
    name: 'SIAL SHENZHEN',
    plateImage: 'assets/img/exhibitions/sial/shenzhen-plate.jpg',
    star: {
      titleTop: '97%',
      subtitleTop: 'участников удовлетворены',
      titleBottom: '94%',
      subtitleBottom: 'гостей удовлетворены',
    },
    firstCol: {
      name: 'Показатели рынка',
      items: [
        'Регион с <strong>70 миллионами</strong> жителей',
        'Доступ к китайскому рынку с <strong>1,4 млрд</strong> потребителей',
        'за следующие 5 лет ВВП региона <strong>удвоится</strong>',
      ],
    },
    secondCol: null,
    videos: [],
  },
  {
    city: 'Лас-Вегас',
    cityImage: 'assets/img/exhibitions/sial/monreale-city.png',
    date: '22 - 24 марта 2022',
    dark: true,
    titleStyle: 'big',
    pos: 70,
    description: `Emerald Holding, Inc. (NYSE: EEX) и Comexposium объявили о том, что они объединяют усилия в рамках стратегического партнерства для запуска SIAL США – нового салона продовольствия и напитков.
    Comexposium, один из ведущих мировых организаторов мероприятий выставочной индустрии и владелец бренда SIAL, объединится с Emerald, лидером на рынке платформ для взаимодействия бизнеса, для запуска новой выставки в США.
    Ожидается, что SIAL США станет первой крупномасштабной универсальной продовольственной выставкой, нацеленной на поддержку рынка продуктов питания и напитков стоимостью 1,5 триллиона долларов США.
<br><br>
    Дебют SIAL США планируется в конференц-центре Лас-Вегаса, США, с 22 по 24 марта 2022 года.
    Выставка будет проводится совместно с International Pizza Expo и International Artisan Bakery Expo.
<br><br>
    Ожидается, что SIAL США будет включать продукты из следующих категорий:<br>
    - Органические продукты и Здоровое питание<br>
    - Напитки<br>
    - Бакалея<br>
    - Сладости и выпечка<br>
    - Фрукты и овощи<br>
    - Морепродукты<br>
    - Молочные продукты<br>
    - Замороженные продукты<br>
    - Снэки и Мясо.
`,
    descriptionShort:
      'Единственная в Америке универсальная выставка и конференция пищевой промышленности',
    idString: 'las-vegas',
    image: 'assets/img/exhibitions/sial/las-vegas.png',
    location: 'Las Vegas Convention Center',
    name: 'SIAL USA',
    plateImage: 'assets/img/exhibitions/sial/las-vegas-plate.jpg',
    star: {
      titleTop: '1,5',
      subtitleTop: ' триллиона долларов на поддержку рынка',
      titleBottom: '97%',
      subtitleBottom: 'гостей удовлетворены',
    },
    firstCol: {
      name: 'Показатели рынка',
      items: [
        'В совокупности выставки ежегодно привлекают более <strong>700 000</strong> посетителей и <strong>16 000</strong> экспонентов из <strong>200</strong> стран.',
        'Доступ к Американскому рынку потребителей',
      ],
    },
    secondCol: null,
    videos: [],
  },

  // projects
  {
    city: 'Бали',
    cityImage: 'assets/img/exhibitions/new-projects/city-img.png',
    date: '07-09 СЕНТЯБРЯ 2023',
    pos: 50,
    type: 'projects',
    description:
      'Продуктовая категория представлена на выставке: ' +
      '- молочная продукция, яйца - мясо, мясо птицы - свежая рыба, моллюски и ракообразные - свежие овощи и фрукты - кондитерские изделия, печенье и выпечка - хлебобулочные изделия - деликатесы и заменители еды - пресервы и консервы - бакалея - мороженое ' +
      '- замороженные продукты - здоровое питание и диетические продукты - вино и крепкие спиртные напитки - безалкогольные напитки - чай, кофе, шоколад - индустрия гостеприимства - оборудование и технологии - розничная торговля',
    descriptionShort: 'Продовольственная выставка номер один в мире',
    idString: 'prbali',
    image: 'assets/img/exhibitions/new-projects/paris.png',
    location: 'BALI NUSA DUA CONVENTION CENTRE - INDONESIA',
    name: 'BALI NUSA DUA CONVENTION CENTRE - INDONESIA',
    plateImage: 'assets/img/exhibitions/new-projects/page-logo/baliinterfood.png',
    mainColor: '#FF9B2A',
    logo: 'assets/img/header-icons/inter_food.svg',
    headerBgImg: 'assets/img/exhibitions/new-projects/bg-header/bali-bg-header.png',
    promoUrl: 'bali-interfood.com',
    title: 'BALI INTERFOOD EXPO',
    buttonColor: '#FFEDDC',
    buttonTitleColor: '#E26F04',
    star: {
      titleTop: '214+',
      subtitleTop: 'экспонентов',
      titleBottom: '16 630+',
      subtitleBottom: 'профессиональных посетителей',
    },
    firstCol: {
      name: 'Показатели рынка',
      items: [
        '<strong>23%</strong> — представители ритейла, туриндустрии, школы, производителя упаковки и др.',
        '<strong>31%</strong> — владельцы ресторанов',
        '<strong>24%</strong> — представители отелей',
        '<strong>22%</strong> — владельцы кафе',
      ],
    },
    secondCol: {
      name: 'Ведущие показатели',
      items: [
        '<strong>16+</strong> стран',
        '<strong>4 400 м2</strong> — площадь выставочной экспозиции',
        '<strong>1000+</strong> целевых встреч в формате match making',
        '<strong>20+</strong> параллельных событий (профильных круглых столов, семинаров, презентаций)',
      ],
    },
    videos: [],
  },
  {
    city: 'Джакарта',
    cityImage: 'assets/img/exhibitions/new-projects/city-img.png',
    date: '21-23 СЕНТЯБРЯ 2023',
    pos: 20,
    type: 'projects',
    description:
      'INDO BEAUTY EXPO — новая международная выставка инноваций в области красоты и косметики. В рамках выставки проводятся деловые встречи и семинары по экпорту и инвестированию, демонстрируются тенденции в области красоты.',
    descriptionShort: 'Международная выставка инноваций в области красоты и косметики',
    idString: 'prdjakarta',
    image: 'assets/img/exhibitions/new-projects/china.png',
    location: 'JAKARTA INTERNATIONAL EXPO, KEMAYORAN',
    name: 'JAKARTA INTERNATIONAL EXPO, KEMAYORAN',
    plateImage: 'assets/img/exhibitions/new-projects/page-logo/indobeautyexpo.png',
    headerBgImg: 'assets/img/exhibitions/new-projects/bg-header/header-bg-beauty.png',
    mainColor: '#ED7092',
    logo: 'assets/img/header-icons/indo_beauty.svg',
    promoUrl: 'indobeautyexpo.com',
    title: 'INDO BEAUTY EXPO',
    buttonColor: '#FFE2F3',
    buttonTitleColor: '#BF0070',
    star: {
      titleTop: '100+',
      subtitleTop: 'экспонентов',
      titleBottom: '3 900м2',
      subtitleBottom: 'площадь выставочной экспозиции',
    },
    firstCol: {
      name: 'Профиль выставки',
      items: [
        'декоративная косметика сырье и компоненты',
        'пищевые добавки',
        'товары для здоровья и СПА-индустрии',
        'уходовая косметика',
        'натуральные продукты, функциональные продукты питания',
        'натуропатия',
        'средства ухода за волосами и телом',
        'пластическая и лазерная хирургия',
      ],
    },
    secondCol: {
      name: 'Ведущие показатели',
      items: [
        '<strong>17+</strong> стран',
        '<strong>5 760 м2</strong> — площадь выставочной экспозиции',
      ],
    },
    videos: [],
  },
  {
    city: 'Джакарта',
    cityImage: 'assets/img/exhibitions/new-projects/city-img.png',
    date: '21-23 СЕНТЯБРЯ 2023',
    pos: 30,
    type: 'projects',
    description:
      'INDO HEALTH CARE – новая международная выставка технологий медицины и охраны здоровья. В рамках выставки проводятся деловые встречи и семинары по экспорту и инвестированию, демонстрируются новинки медицинского оборудования и препаратов.',
    descriptionShort: 'Международная выставка технологий медицины и охраны здоровья',
    idString: 'prdjakarta2',
    image: 'assets/img/exhibitions/new-projects/middle_east.png',
    location: 'JAKARTA INTERNATIONAL EXPO, KEMAYORAN',
    name: 'JAKARTA INTERNATIONAL EXPO, KEMAYORAN',
    plateImage: 'assets/img/exhibitions/new-projects/page-logo/indohealthcare.png',
    headerBgImg: 'assets/img/exhibitions/new-projects/bg-header/header-bg-health.png',
    mainColor: '#0B78BE',
    logo: 'assets/img/header-icons/indo_health.svg',
    promoUrl: 'indohealthcareexpo.com',
    title: 'INDO HEALTH CARE',
    buttonColor: '#E6E3FF',
    buttonTitleColor: '#27215B',
    star: {
      titleTop: '155+',
      subtitleTop: 'экспонентов',
      titleBottom: '7 302+',
      subtitleBottom: 'профессиональных посетителей',
    },
    firstCol: {
      name: 'Профиль выставки',
      items: [
        'медоборудование',
        'продукция медицинского назначения',
        'расходный материал',
        'услуги медицинского страхования',
        'оборудование для реабилитации',
        'натуропатия',
        'пищевые добавки',
      ],
    },
    secondCol: {
      name: 'Ведущие показатели',
      items: ['<strong>33+</strong> стран'],
    },
    videos: [],
  },
  {
    city: 'Эр-Рияд',
    cityImage: 'assets/img/exhibitions/new-projects/saudi.png',
    date: '23-26 ОКТЯБРЯ 2023',
    pos: 40,
    type: 'projects',
    description:
      'В конце 20 века Королевство Саудовская Аравия сформировало стратегию, при которой приоритетным в развитии страны до 2030 года является сельскохозяйственный сектор. В рамках реализации данной программы предоставляется финансовая поддержка отрасли в целом, поощряется интеграция сложных технологий. Эти меры привели к 4-х кратному увеличению внутреннего производства продуктов питания с 1980 по 1990 годы, которого, однако, недостаточно для обеспечения внутренних потребностей государства в продукции АПК. В соответствии с обозначенными целями программы Kingdom of Saudi Arabia Vision 2030, Правительство одобрило пакет стоимостью 24,5 миллиарда долларов для обеспечения программы устойчивой  продовольственной безопасности Королевства.\n' +
      'Saudi Agriculture – это платформа, на которой представлены новейшие технологические достижения и разработки в области производства продуктов питания и оборудования в области сельского хозяйства.\n' +
      'В течение 39 лет выставка Saudi Agriculture сохраняет лидирующую позицию среди аналогичных мероприятий данного региона, являясь динамично развивающейся платформой для демонстрации перспектив развития сельскохозяйственного сектора.\n' +
      'Проходит под патронажем Министерства сельского хозяйства Королевства Саудовская Аравия. Проводится одновременно с Saudi Agriculture, Saudi Agrofood, Saudi Food Pack и Saudi Organic Food.',
    descriptionShort: 'Платформа с новейшими технологиями в области сельского хозяйства',
    idString: 'prelriyadh',
    image: 'assets/img/exhibitions/new-projects/india.png',
    location: 'RIYADH INTERNATIONAL CONVENTION & EXHIBITION CENTRE',
    name: 'RIYADH INTERNATIONAL CONVENTION & EXHIBITION CENTRE',
    plateImage: 'assets/img/exhibitions/new-projects/page-logo/agriculture.png',
    headerBgImg: 'assets/img/exhibitions/new-projects/bg-header/header-bg-agri.png',
    mainColor: '#084B28',
    logo: 'assets/img/header-icons/logo-saudi.svg',
    promoUrl: 'saudi-agriculture.com',
    title: 'SAUDI AGRICULTURE',
    buttonColor: '#D9FFE6',
    buttonTitleColor: '#094C29',
    star: {
      titleTop: '257',
      subtitleTop: 'экспонентов',
      titleBottom: '21 027',
      subtitleBottom: 'профессиональных посетителей',
    },
    firstCol: {
      name: 'Профиль выставки',
      items: [
        'ячмень',
        'кукуруза',
        'пшеница',
        'подсолнечное масло',
        'продукты перемола',
        'мороженое',
        'шоколадные кондитерские изделия',
        'продукты для приготовления соусов',
        'разнообразные кондитерские изделия',
        'соевые бобы',
        'мясо баранины',
        'мясо птицы',
        'маргарин',
      ],
    },
    secondCol: {
      name: 'Ведущие показатели',
      items: [
        '<strong>31</strong> страна',
        '<strong>9</strong> национальных павильонов',
        '<strong>18 220 м2</strong> – площадь выставочной экспозиции',
      ],
    },
    videos: [],
  },
  {
    city: 'Эр-Рияд',
    cityImage: 'assets/img/exhibitions/new-projects/saudi.png',
    date: '06-09 НОЯБРЯ 2023',
    pos: 50,
    type: 'projects',
    description:
      'За последние несколько лет в Королевстве Саудовская Аравия наблюдается беспрецедентный бум строительных мегапроектов, который изменит будущее Королевства и региона. Мегапроекты государственного и частного секторов финансируется Государственным инвестиционным фондом, Королевскими комиссиями основных городских районов, профильными министерствами, а также частными сектором, который реализует наибольшее количество проектов и возможностей. Производители, предлагающие надежные и устойчивые строительные технологии и решения, получат беспрецедентную возможность участия в проектах, реализуемых на сумму более 2,9 трлн. долларов США.\n' +
      'В течение последних 32 лет выставка SAUDI BUILD служит надежной, проверенной временем платформой B2B общения. Традиционно, выставка предлагает неограниченные возможности заранее назначить и организовать встречу с профессионалами, принимающими решения. Площадка объединяет дистрибьюторов, собственников и руководителей проектов, консультантов, архитекторов, инженеров, комплектовщиков.',
    descriptionShort: 'Платформа B2B общения по темам строительства',
    idString: 'prelriyadh2',
    image: 'assets/img/exhibitions/new-projects/monreal.png',
    location: 'RIYADH INTERNATIONAL CONVENTION & EXHIBITION CENTRE',
    name: 'RIYADH INTERNATIONAL CONVENTION & EXHIBITION CENTRE',
    plateImage: 'assets/img/exhibitions//new-projects/page-logo/saudibuild.png',
    headerBgImg: 'assets/img/exhibitions/new-projects/bg-header/header-bg-build.png',
    mainColor: '#0C74AB',
    logo: 'assets/img/header-icons/build.svg',
    promoUrl: 'saudi-build.com',
    title: 'SAUDI BUILD',
    buttonColor: '#DDF3FF',
    buttonTitleColor: '#033762',
    star: {
      titleTop: '570+',
      subtitleTop: 'экспонентов',
      titleBottom: '24 766',
      subtitleBottom: 'профессиональных посетителей',
    },
    firstCol: {
      name: 'Профиль выставки',
      items: [
        'стройматериалы и стройинструмент',
        'строительные технологии и услуги',
        'дизайн интерьера и финишная отделка',
        'обработка и производство изделий из натурального камня',
        'отпление, вентиляция, кондиционирование',
        'станки, тяжелая строительная техника',
        'изделия из бетона',
      ],
    },
    secondCol: {
      name: 'Ведущие показатели',
      items: [
        '<strong>44</strong> страны',
        '<strong>9+</strong> национальных павильонов',
        '<strong>22 700 м2</strong> – площадь выставочной экспозиции',
      ],
    },
    videos: [],
  },
  {
    city: 'Абу-Даби',
    cityImage: 'assets/img/exhibitions/new-projects/saudi.png',
    date: '27-29 НОЯБРЯ 2023',
    pos: 60,
    dark: true,
    type: 'projects',
    description: 'Объединенные Арабские Эмираты отличаются открытой экономикой',
    descriptionShort: 'ОАЭ - центр деловой жизни ближнего востока',
    idString: 'adife',
    image: 'assets/img/exhibitions/new-projects/dhabi.png',
    location: 'Abu Dhabi National Exhibition Centre (ADNEC)',
    name: 'ABY DHABI NATIONAL EXHIBITION CENTRE (ADNEC)',
    plateImage: 'assets/img/exhibitions//new-projects/page-logo/adife-bg.png',
    // headerBgImg: 'assets/img/exhibitions/new-projects/bg-header/header-bg-build.png',
    mainColor: '#8B1536',
    logo: 'assets/img/header-icons/abu_food.svg',
    promoUrl: 'adife.com',
    title: 'ABU DHABI INTERNATIONAL FOOD EXHIBITION',
    buttonColor: '#DDF3FF',
    buttonTitleColor: '#033762',
    star: {
      titleTop: '9 млн',
      subtitleTop: 'человек население',
      titleBottom: '$67 700',
      subtitleBottom: 'ВВП на душу населения',
    },
    firstCol: {
      name: 'Ведущие показатели',
      items: [
        '<strong>85%</strong> посетителей выставки - люди принимающие решение',
        '<strong>Пришлашенные байеры</strong> - целевые встречи в формате MATCHMAKING',
        '<strong>ABU DHABI WORLD GOURMET SHOW</strong> – мастер классы и презентации от звездных шеф поваров',
      ],
    },
    videos: [],
  },
];

export const EXHIBITIONS_SIMA: ExhibitionSimaInterface[] = [
  {
    date: '24-28 ноября 2024',
    image: 'assets/img/exhibitions/sima/sima-paris.png',
    link: 'https://en.simaonline.com/',
    location: 'Paris Nord Villepinte',
    logo: 'assets/img/exhibitions/sima/sima-paris-logo.png',
    data: {
      city: 'Париж',
      date: '24-28 ноября 2024',
      description:
        '<strong>SIMA (Salon International de la Machine Agricole)</strong> – крупнейшая международная профессиональная выставка ' +
        'машиностроения для сельского хозяйства и животноводства. В <strong>2022 году</strong> выставка пройдет <strong>с 6 по 10 ноября</strong> в выставочном ' +
        'центре Paris-Nord Villepinte Exhibition Centre.<br><br>' +
        '<strong>Мероприятия и возможности салона SIMA:</strong><br><br>' +
        '<ul class="pl-3 mb-0"><li>Международные семинары и презентации, направленные на получение информации об особенностях  сельского хозяйства в мире</li>' +
        '<li>Центр геномных исследований SIMAGENA - новые достижения и открытия в этой области</li>' +
        '<li>Инновационная зона - демонстрация новинок в секторе сельскохозяйственного оборудования</li>' +
        '<li>SIMA Innovation Awards – премия, которая присуждается инновационным образцам сельскохозяйственной техники и оборудования, а также новаторским и перспективным услугам</li>' +
        'И другие…</ul>' +
        '<br><img class="w-100" src="/assets/img/exhibitions/sima/chart.png" alt="">' +
        '<br><br><strong>География посетителей (без учета Западной Европы):</strong><br><br>' +
        '<img class="w-100" src="/assets/img/exhibitions/sima/sima-paris-map.png" alt="">',
      descriptionShort: 'Сельское хозяйство: движение вперёд!',
      idString: 'paris',
      image: 'assets/img/exhibitions/sima/sima-paris.png',
      location: 'Paris Nord Villepinte',
      name: 'SIMA',
      plateImage: 'assets/img/exhibitions/sima/sima-paris.png',
      star: {
        titleTop: '93%',
        subtitleTop: 'участников удовлетворены',
        titleBottom: '92%',
        subtitleBottom: 'вновь примут участие',
      },
      firstCol: {
        name: 'SIMA 2021',
        items: [
          '<strong>1800</strong> компаний-участниц из <strong>42</strong> стран',
          '<strong>230 000</strong> посетителей из <strong>140</strong> стран',
          '<strong>25%</strong> международных посетителей',
          '<strong>400</strong> международных делегаций',
        ],
      },
      secondCol: {
        name: 'Тематические разделы выставки SIMA',
        items: [
          'Продукция и услуги общего назначения',
          'Продукция специального назначения: основные культуры, животноводство, специальные культуры',
          'Возобновляемые источники энергии',
          'SIMAGENA – встреча с селекционерами со всего мира',
        ],
      },
    },
  },
];

export const EXHIBITIONS_PROJECTS: ExhibitionProjectInterface[] = [
  // {
  //   image: 'assets/img/exhibitions/projects/project-1.jpg',
  //   imageSmall: 'assets/img/exhibitions/projects/project-1-small.jpg',
  //   location: 'SIAL Interfood 2017',
  //   size: '120 м2',
  //   title: 'Объединённая экспозиция российских производителей Made in Russia',
  // },
  // {
  //   image: 'assets/img/exhibitions/projects/project-2.png',
  //   imageSmall: 'assets/img/exhibitions/projects/project-2-small.jpg',
  //   location: 'SIAL China 2019',
  //   size: '168 м2',
  //   title: 'Российский экспортный центр',
  // },
  // {
  //   image: 'assets/img/exhibitions/projects/project-3.png',
  //   imageSmall: 'assets/img/exhibitions/projects/project-3-small.png',
  //   location: 'SIAL China 2019',
  //   size: '84 м2',
  //   title: 'Мираторг',
  // },
  // {
  //   image: 'assets/img/exhibitions/projects/project-4.png',
  //   imageSmall: 'assets/img/exhibitions/projects/project-4-small.png',
  //   location: 'SIAL China 2019',
  //   size: '168 м2',
  //   title: 'Московский экспортный центр',
  // },
  // {
  //   image: 'assets/img/exhibitions/projects/project-5.png',
  //   imageSmall: 'assets/img/exhibitions/projects/project-5-small.png',
  //   location: 'Продэкспо 2019',
  //   size: '203 м2',
  //   title: 'Business Armenia',
  // },
  // {
  //   image: 'assets/img/exhibitions/projects/project-6.png',
  //   imageSmall: 'assets/img/exhibitions/projects/project-6-small.png',
  //   location: 'SIAL China 2019',
  //   size: '90 м2',
  //   title: 'Черкизово',
  // },
  // {
  //   image: 'assets/img/exhibitions/projects/project-7.png',
  //   imageSmall: 'assets/img/exhibitions/projects/project-7-small.png',
  //   location: 'SIAL China 2019',
  //   size: '180 м2',
  //   title: 'Азпромо',
  // },
  // {
  //   image: 'assets/img/exhibitions/projects/project-8.png',
  //   imageSmall: 'assets/img/exhibitions/projects/project-8-small.png',
  //   location: 'SIAL China 2018',
  //   size: '36 м2',
  //   title: 'Юниконф',
  // },
  // {
  //   image: 'assets/img/exhibitions/projects/project-9.png',
  //   imageSmall: 'assets/img/exhibitions/projects/project-9-small.png',
  //   location: 'SIAL China 2019',
  //   size: '33 м2',
  //   title: 'Ресурс',
  // },
  // {
  //   image: 'assets/img/exhibitions/projects/project-10.png',
  //   imageSmall: 'assets/img/exhibitions/projects/project-10-small.png',
  //   location: 'SIAL China 2018',
  //   size: '36 м2',
  //   title: 'Московская пивоваренная компания',
  // },
  // {
  //   image: 'assets/img/exhibitions/projects/project-11.png',
  //   imageSmall: 'assets/img/exhibitions/projects/project-11-small.png',
  //   location: 'SIAL China 2018',
  //   size: '36 м2',
  //   title: 'Невский кондитер',
  // },
  // {
  //   image: 'assets/img/exhibitions/projects/project-12.png',
  //   imageSmall: 'assets/img/exhibitions/projects/project-12-small.png',
  //   location: 'SIAL China 2018',
  //   size: '36 м2',
  //   title: 'Makfa',
  // },
  // {
  //   image: 'assets/img/exhibitions/projects/project-13.png',
  //   imageSmall: 'assets/img/exhibitions/projects/project-13-small.png',
  //   location: 'SIAL China 2018',
  //   size: '168 м2',
  //   title: 'Российский экспортный центр',
  // },
];
