import { Location } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NewsItemInterface, NewsService } from '../../../shared';

class ImageSnippet {
  constructor(public src: string, public file: File) { }
}

@Component({
  selector: 'nw-updater-layout',
  templateUrl: './updaterlayout.component.html',
  styleUrls: ['updaterlayout.component.scss']
})
export class NewsUpdaterLayoutComponent implements OnInit {

  @ViewChild('readOnlyTemplate', { static: false }) readOnlyTemplate: TemplateRef<any>;
  @ViewChild('editTemplate', { static: false }) editTemplate: TemplateRef<any>;

  editedNews: any;
  data: any;
  formsOpen: boolean[];
  isNewRecord: boolean;
  newsText: string;
  tokenPresent: boolean;
  lastEditedImage: string;
  fileName: string;
  onLoading: boolean;

  editorStyle = {
    'height': '300px',
    'backgroundColor': '#ffffff',
    'color': 'rgb(33, 37, 41)',
    'display': 'block',
    'font-family': "Graphik LCG",
    'font-size': '16px',
    'font-weight': '400',
    'line-height': '32px'
  }

  constructor(private newsService: NewsService, private activatedRoute: ActivatedRoute, private router: Router, private location: Location) {
  }

  ngOnInit(): void {

    this.activatedRoute.queryParams.subscribe(data =>
      data.token && data.token === '99229323jasdasd' ? localStorage.setItem('token', data.token) : null);
    this.tokenPresent = localStorage.getItem('token') === '99229323jasdasd';
    !this.tokenPresent ? this.router.navigate(['']) : null;
    this.newsService.readNews().subscribe((data: any[]) => {
      this.data = data.sort((newsA, newsB) =>
          new Date(newsA.date).getTime() > new Date(newsB.date).getTime() ? -1 : 1
      );
    })
    this.location.replaceState("/newsupdater");
  }

  writeNews(): void {
    this.newsService.writeNews(this.data).subscribe((text) => console.log(text));
  }

  addNews() {
    !!this.editedNews ? this.editedNews = null : null;
    if (!this.editedNews) {
      let date: Date = new Date();
      let dateString: string = `${date.getUTCMonth() + 1}/${date.getUTCDate()}/${date.getUTCFullYear()}`
      this.data.unshift({ date: dateString });
      this.editedNews = this.data[0];
    }
  }

  trackByFn(index, item): void {
    return index;
  }

  deleteNews(index: number): void {
    confirm('Удалить?') ? this.data.splice(index, 1) : null;
    this.newsService.writeNews(this.data).subscribe((text) => console.log(text));
  }

  editNews(news) {
    this.lastEditedImage = null;
    this.editedNews = news;
    this.newsText = JSON.stringify(news);
  }

  loadTemplate(news) {
    if (this.editedNews && this.editedNews.title === news.title) {
      return this.editTemplate;
    } else {
      return this.readOnlyTemplate;
    }
  }

  cancel(): void {
    this.newsService.readNews().subscribe((data) => {
      this.data = data;
    })
    this.editedNews = null;
  }

  saveNews() {
    this.newsService.writeNews(this.data).subscribe((text) => console.log(text));
    this.editedNews = null;
  }

  getLinkFromTitle(title: string = ''): void {
    const regex = /[ ]/gm;
    this.editedNews.idString = title.replace(regex, '-').toLowerCase();
  }

  deleteImg() {
    this.lastEditedImage = this.editedNews.image;
    this.editedNews.image = null;
  }

  revertImg() {
    this.editedNews.image = this.lastEditedImage;
  }

  uploadImg(event) {
    this.onLoading = true;
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      this.fileName = `news-image-${this.data.length}.jpg`;
      const [file] = event.target.files;
      reader.readAsDataURL(file);
    }
    reader.onload = () => {
      this.newsService.postImg(this.fileName, reader.result as string).subscribe(data => {
        this.editedNews.image = 'https://media.produktexpo.com/images/' + this.fileName;
        this.onLoading = false;
      })
    };
  }
}
