import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NewsResolver } from '../shared';
import { NewsLayoutComponent } from './components';

export const newsRoutes: Routes = [
  {
    path: ':id',
    pathMatch: 'full',
    resolve: { NewsResolver },
    component: NewsLayoutComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(newsRoutes)],
  exports: [RouterModule]
})
export class NewsRoutingModule { }
