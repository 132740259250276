import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { QuillModule } from 'ngx-quill';
import { SharedModule } from '../shared';
import { NewsUpdaterLayoutComponent } from './components';
import { NewsUpdaterRoutingModule } from './newsupdater-routing.module';

@NgModule({
  imports: [
    CommonModule,
    NewsUpdaterRoutingModule,
    SharedModule,
    FormsModule,
    QuillModule.forRoot()
  ],
  declarations: [
    NewsUpdaterLayoutComponent
  ],
})
export class NewsUpdaterModule { }
