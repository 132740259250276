import { Component, Input, OnChanges } from '@angular/core';
import { ExhibitionSimaInterface } from '../../../../shared';

@Component({
  selector: 'hm-exhibition-sima',
  templateUrl: './exhibition-sima.component.html',
  styleUrls: ['exhibition-sima.component.scss']
})
export class ExhibitionSimaComponent implements OnChanges {

  @Input() data: ExhibitionSimaInterface;

  link: string;

  ngOnChanges(): void {
    if (this.data) {
      if (this.data.data && this.data.data.idString) {
        this.link = '/exhibitions/sima/' + this.data.data.idString;
      } else {
        this.link = this.data.link;
      }
    }
  }
}
