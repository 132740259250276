import { Component, Input, OnInit } from '@angular/core';
import { NewsItemInterface } from '../../../../shared';

@Component({
  selector: 'hm-news-block',
  templateUrl: './news-block.component.html',
  styleUrls: ['news-block.component.scss']
})
export class NewsBlockComponent implements OnInit {

  @Input() news: NewsItemInterface[];

  newsGroups: NewsItemInterface[][];

  ngOnInit(): void {
    if (this.news.length === 6) {
      this.newsGroups = [
        [this.news[0], this.news[1]], [this.news[2], this.news[3]], [this.news[4], this.news[5]]
      ];
    } else if (this.news.length === 5) {
      this.newsGroups = [
        [this.news[0]], [this.news[1], this.news[2]], [this.news[3], this.news[4]]
      ];
    } else if (this.news.length === 4) {
      this.newsGroups = [
        [this.news[0]], [this.news[1]], [this.news[2], this.news[3]]
      ];
    } else if (this.news.length === 3) {
      this.newsGroups = [
        [this.news[0]], [this.news[1]], [this.news[2]]
      ];
    } else if (this.news.length === 2) {
      this.newsGroups = [
        [this.news[0]], [this.news[1]]
      ];
    } else {
      this.newsGroups = [
        [this.news[0]]
      ];
    }
  }
}
