import { isPlatformBrowser } from '@angular/common';
import { Component, HostListener, Inject, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ItemInterface } from '../../../shared';

const PARTNERS_COUNT: number = 32;
const PARTNERS_LIMIT_XL: number = 15;
const PARTNERS_LIMIT_LG: number = 12;
const PARTNERS_LIMIT_SM: number = 10;

@Component({
  selector: 'hm-partners-section',
  templateUrl: './partners-section.component.html',
  styleUrls: ['partners-section.component.scss']
})
export class PartnersSectionComponent {

  showAll$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  isBrowser: boolean = isPlatformBrowser(this.platformId);
  lg: boolean;
  sm: boolean;
  partners: ItemInterface[] = [];

  constructor(@Inject(PLATFORM_ID) private platformId) {
    this.lg = this.isLg;
    this.sm = this.isSm;
    this.initPartners();

    this.showAll$.subscribe((value: boolean) => {
      this.initPartners();
    });
  }

  get isLg(): boolean {
    return this.isBrowser ? window.innerWidth <= 1200 && window.innerWidth > 768 : false;
  }

  get isSm(): boolean {
    return this.isBrowser ? window.innerWidth <= 768 : false;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (this.isLg !== this.lg || this.sm !== this.isSm) {
      this.lg = this.isLg;
      this.sm = this.isSm;
      this.initPartners();
    }
  }

  toggleShow(): void {
    this.showAll$.next(!this.showAll$.value);
  }

  private initPartners(): void {
    let max: number;
    if (this.showAll$.value) {
      max = PARTNERS_COUNT;
    } else {
      if (this.lg) {
        max = PARTNERS_LIMIT_LG;
      } else if (this.sm) {
        max = PARTNERS_LIMIT_SM;
      } else {
        max = PARTNERS_LIMIT_XL;
      }
    }
    this.partners = [];
    for (let i = 1; i <= max; i++) {
      this.partners.push({ icon: `assets/img/partners/partner-${i}.jpg` });
    }
  }
}
