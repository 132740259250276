import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, PLATFORM_ID } from '@angular/core';
import { ExhibitionProjectInterface, EXHIBITIONS_PROJECTS } from '../../../../shared';

declare var Swiper: any;

@Component({
  selector: 'hm-exhibitions-projects-swiper',
  templateUrl: './exhibitions-projects-swiper.component.html'
})
export class ExhibitionsProjectsSwiperComponent {

  exhibitionsProjects: ExhibitionProjectInterface[] = EXHIBITIONS_PROJECTS;
  isBrowser: boolean = isPlatformBrowser(this.platformId);
  swiper: any;

  constructor(@Inject(PLATFORM_ID) private platformId) {
  }

  init(): void {
    setTimeout(() => {
      if (this.isBrowser) {
        this.swiper = new Swiper('#projects-swiper', {
          // Optional parameters
          direction: 'horizontal',
          loop: false,
          // Navigation arrows
          navigation: {
            nextEl: '#projects-swiper-button-next',
            prevEl: '#projects-swiper-button-prev',
          }
        });
      }
    }, 200);
  }
}
