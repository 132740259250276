import { Component, Input, OnInit } from '@angular/core';
import { NewsItemInterface } from '../../../../shared';

@Component({
  selector: 'hm-news-item',
  templateUrl: './news-item.component.html',
  styleUrls: ['news-item.component.scss']
})
export class NewsItemComponent implements OnInit {

  @Input() data: NewsItemInterface;
  @Input() fullHeight: boolean = false;

  date: string;

  ngOnInit(): void {
    const dateParts: string[] = this.data.date.split('/');
    const date: Date = new Date(Number(dateParts[2]), Number(dateParts[0]) - 1, Number(dateParts[1]));
    this.date = date.toLocaleString('ru', {  month: 'long', day: '2-digit', year: 'numeric' });
    this.date = this.date.slice(0, this.date.length - 3);
  }
}
