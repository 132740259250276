import { Component, Input } from '@angular/core';
import { ExhibitionInterface } from '../../../../shared';

@Component({
  selector: 'hm-exhibition-sial',
  templateUrl: './exhibition-sial.component.html',
  styleUrls: ['exhibition-sial.component.scss']
})
export class ExhibitionSialComponent {

  @Input() data: ExhibitionInterface;
}
