import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { ExhibitionInterface, EXHIBITIONS_SIAL, EXHIBITIONS_SIMA } from '../../../shared';

@Component({
  selector: 'ex-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['layout.component.scss'],
})
export class ExhibitionLayoutComponent implements OnInit {
  data: ExhibitionInterface;
  type: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.pipe(take(1)).subscribe((params: Params) => {
      this.type = params.type;
      if (params.type !== 'sial' && params.type !== 'sima') {
        this.goBack();
      }
      const exhibitions: ExhibitionInterface[] =
        params.type === 'sial' ? EXHIBITIONS_SIAL : EXHIBITIONS_SIMA.map(item => item.data);
      this.data = exhibitions.find(item => item.idString === params.id);
      if (!this.data) {
        this.goBack();
      }
    });
  }

  get getProjectBackground(): string {
    if (this.data.type === 'projects') {
      return 'linear-gradient(180deg,' + this.data.mainColor + ' 44.27%,' + '#ffffff' + ' 100%)';
    }
  }

  get projectExhib(): boolean {
    return this.data.type === 'projects';
  }

  openUrl(url: string): void {
    window.open(`https://www.${url}`, '_blank');
  }

  getVideoUrl(link: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(link);
  }

  private goBack(): void {
    this.router.navigate(['/']);
  }
}
